/* .image-hover:hover {
    background-color: red;
} */

.image-container:hover>.image-hover {
    display: flex;
    flex-direction: col;
    align-items: center;
    justify-content:  center;
     background-color: rgba(0,0,0,0.5);
}
